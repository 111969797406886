import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Box } from 'theme-ui'
import SEO from '../components/seo'

const TermsAndConditionsPage = () => (
  <React.Fragment>
    <SEO title='Términos y Condiciones' />
    <Box
      sx={{
        textAlign: 'left',
        p: [15, 30],
        maxWidth: 1200,
        margin: '0 auto',
      }}
    >
      <Box as="h1" sx={{ fontSize: [35, 50], mb: [15, 30], textAlign: 'center' }}>
        Términos y Condiciones
      </Box>
      <Box
        sx={{
          display: 'block',
          bg: 'bg_header',
          border: '1px solid',
          borderColor: 'border',
          p: [20, 30],
        }}>
        <h3>Lorem Ipsum</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris auctor nibh nec semper condimentum. Cras id turpis quis nisl facilisis efficitur quis ac nisi. Etiam auctor risus tellus, in commodo odio efficitur ut. Cras eu nunc mauris. Donec at arcu tempus, dapibus est vel, sollicitudin orci. Integer eu consectetur enim. Maecenas faucibus fermentum nisi, interdum posuere turpis tincidunt sed. Cras sodales, velit non sodales congue, purus nisi feugiat elit, eu vestibulum lacus mi id odio. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Pellentesque sit amet urna lectus. Phasellus non pulvinar lorem. Curabitur hendrerit imperdiet vehicula. Pellentesque ornare turpis sit amet egestas rutrum. Sed congue fringilla dictum. Vivamus quis velit nisl.</p>
        <p>Ut ac ante efficitur, rhoncus nisi et, ultrices enim. Aliquam nec venenatis ipsum. Sed finibus enim fermentum nisi rutrum, in sodales metus varius. Quisque ultricies eros a tortor interdum blandit. Proin eu enim massa. Morbi auctor at leo eget interdum. Donec cursus faucibus volutpat. Sed ac iaculis libero. Ut et libero massa.</p>
        <p>Suspendisse potenti. Vivamus dictum tincidunt neque dapibus malesuada. Aliquam erat volutpat. Nam nunc sapien, consequat id est a, semper fermentum felis. In rhoncus tincidunt pretium. Praesent mollis magna in leo consequat pulvinar. Nam eget molestie sem. Etiam non porta purus. Phasellus pellentesque massa sodales, aliquam sapien ut, imperdiet ipsum. Sed facilisis pulvinar eros, nec rutrum felis. Nam sit amet pretium ligula. Fusce finibus urna eu tempor volutpat. Sed non enim quam. Donec sagittis orci vel mi cursus mollis.</p>
        <p>Ut fringilla sapien ut sollicitudin dapibus. Nunc sagittis id dui in tempus. Integer rhoncus, sem nec eleifend vestibulum, odio enim porta augue, vitae semper erat ante in risus. Nulla placerat ultrices ligula, sed rutrum nulla vestibulum varius. Curabitur felis ex, iaculis eget lorem in, elementum imperdiet eros. Aenean nec dui sapien. Nulla pulvinar risus lorem, quis lobortis neque ullamcorper sed. Nullam dapibus et massa eu fermentum. In hac habitasse platea dictumst. Quisque tempor bibendum nulla.</p>
        <p>Donec quis lacus sit amet est vehicula efficitur eget eget nibh. Quisque risus ex, pulvinar non leo placerat, luctus ultrices quam. Fusce odio ex, ullamcorper eget fringilla quis, gravida et erat. Curabitur ultricies euismod massa nec malesuada. Nullam tempus libero at ante dictum, a condimentum lacus pulvinar. Nullam venenatis nulla et velit vulputate, at dictum enim congue. Pellentesque a lacus efficitur, aliquam odio et, aliquam dui.</p>
        <hr style={{ mt: 30 }} />
        <Row
          sx={{ margin: 0 }}
        >
          <Col md={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <p>Atentamente,<br />
              <strong>iComm Solutions S.A.S.</strong>
            </p>
          </Col>
          <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <small>Fecha Actualización:<br />
              1 de Septiembre de 2020
            </small>
          </Col>
        </Row>
      </Box>
    </Box>
  </React.Fragment>
)

export default TermsAndConditionsPage
